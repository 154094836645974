<template>
  <div class="cj-field">
    <div class="cj-field-content">
      <div class="cj-field-header">
        <div class="cj-label">
          <div class="cj-label-title">{{ $t("planDuration.firstPrimaryEvent") }}&nbsp;</div>
        </div>
      </div>

      <div v-if="showDateInput" class="date-input">
        <DateInput />
        <p :class="{ error: hasError }">{{ planDurationMessage }}</p>
      </div>
      <div v-else class="date-input">
        <div class="events-date">
          {{ firstPrimaryEvent.eventDate }}
        </div>
        <p :class="{ error: hasError }">{{ planDurationMessage }}</p>
      </div>
    </div>
    <div class="cj-field-content">
      <p class="under-label">
        {{ $t("planDuration.longer", [MAX_EVENTS_WEEKS_DURATION]) }}
        <a
          class="learn-more is-underline"
          target="_blank"
          href="https://blog.trainerday.com/coach-jack-block-types-dac5e6d91d27"
          >{{ $t("historyHero.learn") }}</a
        >
      </p>
    </div>
  </div>
</template>
<script>
import { MAX_EVENTS_WEEKS_DURATION, MIN_WEEKS_DURATION, WEEKS_DURATION_CHOOSE_MULTI_BLOCKS } from "@/defaults"
import { mapGetters, mapActions } from "vuex"
import moment from "moment"
import DateInput from "@/components/plan/PlanDurationAndEventDate/DateInput"
import { i18n } from "@/main"

export default {
  name: "PlanEventDate",
  components: {
    DateInput
  },
  data() {
    return {
      MAX_EVENTS_WEEKS_DURATION,
      MIN_WEEKS_DURATION,
      WEEKS_DURATION_CHOOSE_MULTI_BLOCKS
    }
  },
  methods: {
    ...mapActions("plan", ["updatePlanConfigValue"])
  },
  computed: {
    ...mapGetters("plan", ["getTotalWeeksFromDates", "getPlanConfigValue", "firstPrimaryEvent"]),

    hasError() {
      return this.getTotalWeeksFromDates < MIN_WEEKS_DURATION || this.getTotalWeeksFromDates > MAX_EVENTS_WEEKS_DURATION
    },
    events() {
      return this.getPlanConfigValue("events")
    },
    showDateInput() {
      return this.events.length === 1 && this.events.filter(event => event.primary)
    },
    planDurationMessage() {
      if (!this.firstPrimaryEvent) {
        return null
      }

      const { locale } = i18n
      moment.locale(locale)
      if (this.getTotalWeeksFromDates < MIN_WEEKS_DURATION) {
        return this.$t("planDuration.recommend")
      }
      if (this.getTotalWeeksFromDates > MAX_EVENTS_WEEKS_DURATION) {
        return this.$t("planDuration.recommend2", { MAX_EVENTS_WEEKS_DURATION })
      }

      return (
        this.$t("planDuration.info") +
        " " +
        moment
          .duration(this.getTotalWeeksFromDates, "w")
          .locale(locale)
          .humanize({ w: this.getTotalWeeksFromDates + 1 })
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.cj-field {
  display: grid;
  grid-template-columns: auto 475px;
  align-items: flex-end;
  margin: 10px 0;
  border-radius: 0 0 10px 10px;

  @include media("<desktop") {
    flex-direction: column;
    grid-template-columns: auto;
    align-items: center;
    gap: 30px;
    border-radius: 0 10px 10px 10px;
  }
  @include media("<phone") {
    display: flex;
    gap: 16px;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-content {
    @include media("<tablet") {
      max-width: unset;
      min-width: 135px;
    }
  }
}
.events-date {
  display: flex;
  flex-direction: column;
}
.date {
  &-blocks {
    display: none;
    gap: 50px;
    margin-top: 20px;
  }
  &-block {
    position: relative;
    p {
      font-weight: 500;
    }

    .error {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      color: red;
    }
  }
}

.under-label {
  font-size: 12px;
}

.date-input {
  margin-top: 11px;
  display: flex;
  align-items: center;
  gap: 30px;
  font-weight: 500;
  flex-wrap: wrap;

  @include media("<tablet") {
    gap: 15px;
  }

  .starting-date {
    margin-top: -20px;
    flex: 1 1 100%;
    font-size: 12px;
    font-weight: normal;
  }
}
.start-date {
  display: flex;
  align-items: center;
}
.error {
  color: $red;
  text-align: center;
}
.blink {
  animation: blinker 1s linear infinite;
}
.toggle {
  margin-top: 10px;
}
a {
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  color: $black;
}
</style>

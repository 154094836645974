<template>
  <section class="date-picker">
    <date-picker
      v-model="getEventDate"
      format="YYYY-MM-DD"
      class="event-days"
      :clearable="false"
      :lang="lang"
      :disabled-date="dt => dt < moment()"
      :get-classes="getClasses"
    />
  </section>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import moment from "moment"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { DATE_FORMAT } from "@/types"
import { MAX_WEEKS_DURATION } from "@/defaults"

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      moment,
      selected: null,
      highlighted: [],
      date: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      }
    }
  },
  computed: {
    ...mapState(["profileFacts"]),
    ...mapGetters("plan", ["getPlanConfigValue", "firstPrimaryEvent"]),
    eventDates() {
      return this.getPlanConfigValue("events").map(event => moment(event.eventDate).format(DATE_FORMAT))
    },
    getEventDate: {
      get() {
        return moment(this.firstPrimaryEvent.eventDate, DATE_FORMAT).toDate()
      },
      set(value) {
        const events = this.getPlanConfigValue("events")
        if (Array.isArray(events)) {
          events.map(event => {
            if (event.primary) {
              event.eventDate = moment(value).format(DATE_FORMAT)
            }
          })
        }
        this.updatePlanConfigValue({ name: "events", value: events })
      }
    },
    sep() {
      return `      -      `
    }
  },
  methods: {
    ...mapActions("plan", ["updatePlanConfigValue"]),
    ...mapMutations("plan", ["setPlanConfigValue"]),

    getClasses(date) {
      if (this.eventDates.includes(moment(date).format(DATE_FORMAT))) {
        return "picker-event-day"
      }
      return ""
    },
    getWeeksAway(startDate, eventDate) {
      const a = moment(eventDate, "YYYY-MM-DD")
      const b = moment(startDate, "YYYY-MM-DD")
      const days = a.diff(b, "day") + 1
      let weeks = days / 7
      weeks = Math.ceil(weeks)
      if (weeks > MAX_WEEKS_DURATION) weeks = MAX_WEEKS_DURATION
      return weeks
    }
  }
}
</script>

<style lang="scss">

.picker-event-day {
  background-color: #ffc700 !important;
  color: #0a0a0a !important;
  font-weight: bold !important;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
}
.event-days {
  .mx-input-wrapper {
    .mx-input {
      font-size: 16px;
    }
  }
}

.plan-start {
  background-color: rgba(45, 175, 0, 0.45) !important;
  color: white !important;
  position: relative;

  &:before {
    display: none;
    position: absolute;
    content: "Your plan starts here";
    width: 120px;
    height: 20px;
    top: -20px;
    left: 100%;
    color: black;
    background-color: #efdede;
  }

  &:hover {
    &:before {
      display: block;
    }
  }
}
</style>

<template>
  <div class="cj-field days-names">
    <div v-for="day in days" :key="day.name" ref="daysNames" class="is-flex is-flex-direction-column">
      <div class="days-header" :class="{ 'days-name': day.isEnabled }">
        <label :for="`day-${day.name}`">
          {{ getDayName(day.name) }}
        </label>
        <input
          type="checkbox"
          :id="`day-${day.name}`"
          :key="day.name"
          class="checkbox-input"
          :class="{ disabled: isDayDisabled(day.isEnabled) }"
          :checked="day.isEnabled"
          @change="changeValueForSpecificDay(day.name, !day.isEnabled, 'isEnabled')"
          :disabled="isDayDisabled(day.isEnabled)"
        />
      </div>

      <div class="is-flex is-flex-direction-column" style="gap:6px" v-if="day.isEnabled">
        <div class="day-option">
          <label>{{ $t("common.duration") }}:</label>
          <div class="select">
            <select
              type="text"
              @change="event => changeValueForSpecificDay(day.name, Number(event.target.value), 'minutesLimit')"
            >
              <option
                v-for="option in durationOptions"
                :key="option.value"
                :value="option.value"
                :selected="option.value === day.minutesLimit"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="day.minutesLimit !== -1" class="axis-label is-flex" style="text-transform: none;">
          <p>⚠️ {{ $t("trainingDays.automaticIs") }} {{ $t("trainingDays.recommended") }}</p>
        </div>
        <div class="day-option">
          <label>{{ $t("common.type") }}: </label>
          <div class="select">
            <select type="text" @change="event => changeValueForSpecificDay(day.name, event.target.value, 'trainingType')">
              <option
                v-for="option in trainingTypeOptions"
                :key="option.value"
                :value="option.value"
                :selected="option.value === day.trainingType"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="cj-label-title is-flex is-justify-content-space-between">
          <label :for="`longDay-${day.name}`"> {{ $t("longRides.input") }} </label>
          <input
            type="checkbox"
            :id="`longDay-${day.name}`"
            :key="day.name"
            class="checkbox-input"
            :checked="day.isBestForLongRide"
            @change="changeValueForSpecificDay(day.name, !day.isBestForLongRide, 'isBestForLongRide')"
          />
        </div>
      </div>
    </div>
    <p>{{ $t("trainingDays.switchWorkouts") }}</p>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import { DEFAULT_DAILY_MINUTES } from "@/defaults"

export default {
  name: "TrainingDays",

  data() {
    return {
      durationOptions: [
        { name: this.$t("common.auto"), value: DEFAULT_DAILY_MINUTES },
        ...[20, 30, 45, 60, 75, 90, 120, 180].map(el => ({ value: el, name: `${el} Min` }))
      ],
      trainingTypeOptions: [
        { name: this.$t("trainingDays.indoor"), value: "indoor" },
        { name: this.$t("trainingDays.outdoor"), value: "outdoor" },
        { name: this.$t("trainingDays.heart_rate"), value: "heart_rate" }
      ]
    }
  },

  computed: {
    ...mapState("plan", ["values"]),
    ...mapGetters("plan", ["getBlockConfigValue"]),

    days() {
      return this.getBlockConfigValue("days")
    }
  },
  methods: {
    ...mapActions("plan", ["updateActiveBlockConfigValue"]),

    isDayDisabled(isEnabled) {
      return this.days?.length === 1 && isEnabled
    },
    getDayName(name) {
      return this.values.workoutDaysValues.find(d => d.value === name).name
    },
    changeValueForSpecificDay(dayName, value, field) {
      this.updateActiveBlockConfigValue({
        name: "days",
        value: this.days.map(day => {
          if (day.name === dayName) {
            return {
              ...day,
              [field]: value
            }
          }
          return day
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme.scss";
.cj-field {
  padding-bottom: 0;
}
.days-names {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(156px, 1fr));
  text-align: center;
  justify-content: space-between;
  row-gap: 16px;

  > div {
    background-color: white;
    padding: 16px 12px;
    max-width: 156px;
  }
  > p:last-child {
    margin-top: -14px;
    margin-bottom: 2px;
    grid-column: 1 / -1;
    opacity: 0.5;
  }
}
.axis-label {
  white-space: nowrap;
  font-size: 11px;
  fill: #b7b7b7;
  text-transform: capitalize;
}
.days-name {
  color: $dark-blue;
  font-weight: 600;
}
.days-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 50px;
  margin-bottom: 6px;
}
.day-option {
  display: flex;
  position: relative;
  white-space: nowrap;
}
.disabled {
  cursor: not-allowed;
}

.select {
  height: unset !important;
  position: unset;
  width: 100%;

  &:not(.is-multiple):not(.is-loading)::after {
    position: absolute;
    right: 2px;
    padding-right: 2px;
  }
}
select {
  position: relative;
  border: none;
  outline: none;
  font-weight: bold;
  padding: unset;
  height: unset;
  &:not([multiple]) {
    padding-right: 19%;
    width: 100%;
  }
}
label {
  margin-right: 3px;
  text-align: start;
}
</style>

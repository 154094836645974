<template>
  <section class="date-picker">
    <date-picker
      v-model="newRangeDate"
      format="YYYY-MM-DD"
      class="days-off"
      popup-class="days-off-popup"
      :clearable="false"
      :lang="lang"
      :range="true"
      :popup-style="{ zIndex: 9999 }"
      :disabled-date="disabledRange"
    />
  </section>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import moment from "moment"
import { DATE_FORMAT } from "@/types"

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      selected: null,
      highlighted: [],
      date: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      }
    }
  },
  props: {
    dateRange: {
      type: Array,
      default: () => []
    },
    userListDaysOff: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    userDaysOff() {
      return this.dateRange
    },
    newRangeDate: {
      get() {
        if (!this.userDaysOff.length) return []
        const daysOff = this.userDaysOff
        return [
          moment(daysOff[daysOff.length - 1].startDate, DATE_FORMAT).toDate(),
          moment(daysOff[daysOff.length - 1].endDate, DATE_FORMAT).toDate()
        ]
      },
      set(values) {
        const startDate = moment(values[0]).format(DATE_FORMAT)
        const endDate = moment(values[1]).format(DATE_FORMAT)
        const duration = moment(endDate).diff(startDate, "days") + 1
        this.userInput = {
          startDate,
          endDate,
          duration
        }
        this.$emit("setInputCalendar", [this.userInput])
      }
    }
  },
  methods: {
    disabledRange(date) {
      const dateExist = this.userListDaysOff.map(dateItem => {
        const from = moment(dateItem.startDate).valueOf()
        const to = moment(dateItem.endDate).valueOf()
        return moment(date).valueOf() >= from && date <= to
      })
      return dateExist.includes(true)
    },
    getClasses(date) {
      if (this.highlighted.find(v => v.getTime() === date.getTime())) {
        return "plan-start"
      }
      return ""
    }
  }
}
</script>

<style lang="scss" scoped>
.days-off {
  &.mx-datepicker-range {
    width: 250px;
  }
  .mx-input-wrapper {
    .mx-input {
      font-size: 16px;
    }
  }
}
</style>

<template>
  <div class="date-picker">
    <date-picker
      v-model="eventDate"
      class="date-picker"
      :clearable="false"
      :lang="lang"
      :popup-style="{ zIndex: 9999 }"
      :disabled-date="disabledDates"
      :get-classes="getClasses"
    />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import moment from "moment"
import { mapGetters } from "vuex"
import { DATE_FORMAT } from "@/types"

export default {
  name: "EventDateInput",
  components: {
    DatePicker
  },
  data() {
    return {
      moment,
      DATE_FORMAT,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      }
    }
  },
  props: {
    date: {
      type: String,
      default: null
    },
    isMultipleEvents: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("plan", ["getPlanConfigValue"]),
    eventDate: {
      get() {
        return this.date ? moment(this.date, DATE_FORMAT).toDate() : null
      },
      set(value) {
        this.$emit("change", moment(value).format(DATE_FORMAT))
      }
    },
    eventDates() {
      return this.getPlanConfigValue("events").map(event => moment(event.eventDate).format(DATE_FORMAT))
    },
    disabledDates() {
      return dt => {
        const formattedDate = moment(dt).format(DATE_FORMAT)
        return this.eventDates.includes(formattedDate) || moment(dt).isBefore(moment(), "day")
      }
    }
  },
  methods: {
    getClasses(date) {
      if (this.eventDates.includes(moment(date).format(DATE_FORMAT))) {
        return "picker-event-day"
      }
      return ""
    }
  }
}
</script>

<style lang="scss">

.date-picker {
  &.mx-datepicker {
    width: auto;
  }

  .mx-input-wrapper {
    .mx-input {
      font-size: 16px;
    }
  }
}

.plan-start {
  background-color: rgba(45, 175, 0, 0.45) !important;
  color: white !important;
  position: relative;

  &:before {
    display: none;
    position: absolute;
    content: "Your plan starts here";
    width: 120px;
    height: 20px;
    top: -20px;
    left: 100%;
    color: black;
    background-color: #efdede;
  }

  &:hover {
    &:before {
      display: block;
    }
  }
}
</style>
